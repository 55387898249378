import { supabase } from "../utils/supabase"
import { useCallback, useState } from "react";
import { RealtimeChannel } from "@supabase/supabase-js";

const useSupabaseChannel = () => {
  const [channel, setChannel] = useState<RealtimeChannel | null>(null);

  const subscribeListener = useCallback((namespace: string) => {
    if (channel) return
    const newChannel = supabase.channel(namespace);
    newChannel.subscribe();

    setChannel(newChannel);

  }, [channel])

  const onChannel = useCallback((eventName: string, handleBroadcast: (payload) => void) => {

    if (!channel) return;
    
    channel.on('broadcast', { event: eventName }, handleBroadcast);

  }, [channel]);

  const unsubscribeChannel = useCallback(() => {
    if (!channel) return;

    channel.unsubscribe();
    setChannel(null);
  }, [channel]);

  return { onChannel, unsubscribeChannel, subscribeListener };
};

export default useSupabaseChannel;
