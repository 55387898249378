import { useEffect } from "react";
import useSupabaseChannel from "../../hooks/useSupabaseChannel";
import { UserRole } from "../../providers/authProvider";
import { getChainId, getCompanyId, getUserId } from "../../utils/utils"
import { useGetIdentity } from "react-admin";

const useActionNotify = (fetchInboxNotification: () => void) => {
  const { unsubscribeChannel, onChannel, subscribeListener } = useSupabaseChannel();
  const { identity } = useGetIdentity();
  const userId = getUserId();
  const audio = new Audio(require('../../assets/notification.mp3'));
  const roomId =
    identity?.role === UserRole.chain
      ? getChainId()
      : identity?.role === UserRole.company
        ? getCompanyId()
        : null;

  useEffect(() => {
    onChannel("notification", handleBroadcast);
  }, [onChannel]);

  useEffect(() => {
    if (roomId) {
      subscribeListener(`room:${roomId}`);
    }
    return () => {
      unsubscribeChannel();
    };
  }, [roomId, subscribeListener, unsubscribeChannel])

  const handleBroadcast = ({ payload }) => {
    const isInvalidParticipant = Array.isArray(payload.participantIds) &&
      payload.participantIds.length > 0 &&
      !payload.participantIds.includes(userId);

    if (isInvalidParticipant) {
      return;
    }
    audio.play();
    fetchInboxNotification();
  };
  return {}
}

export default useActionNotify;
