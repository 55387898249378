import React, { Children, cloneElement, useCallback, useEffect, useState } from 'react';
import {
  IconButton,
  Box,
  Badge,
  Popover,
  Typography,
  Divider,
  List,
  ListSubheader,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Tooltip,
  ListItemButton,
  Button,
} from '@mui/material';
import { RaRecord, ReferenceField, useRecordContext } from 'react-admin';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MessageIcon from '@mui/icons-material/Message';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import InboxIcon from '@mui/icons-material/Inbox';
import { useDataProvider, useRedirect } from 'react-admin';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { get } from 'lodash';
import { useStore } from '../../hooks/useStore';
import { theme } from '../../theme';
import useActionNotify from './useActionNotify';

const PRIMARY_COLOR = theme.palette.primary.main;

const useStyles = makeStyles({
  containerTime: {
    display: 'flex',
    alignItems: 'center',
    height: 15,
  },
  avatar: {
    height: '40px!important',
    width: '40px!important',
    marginLeft: '0px!important',
  }
});

const TitleField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  // console.log({source})
  // const record = {
  //   id: "b8f60b6f-fac2-4e75-be83-3fe5065ba5ce",
  //   chat_id: "0781d6df-ce4c-4c6f-8110-376a78a01e72",
  //   employee_id: "fcf94968-78e2-4787-9f3f-4090306fb307",
  //   body: "teste1",
  //   created_at:"2023-08-09 14:38:35.790982+00",
  //   type: "message"
  // }
  const value = get(record, source);

  return (
    <Typography variant="subtitle2" gutterBottom >{`"${value}"`}</Typography>
  );
}

const NotificationItem = (props) => {
  const notification: any = props.record;

  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const Icon = {
    'chat-message': <MessageIcon />,
  };

  const titleLabel = {
    'chat-message': 'Mensagem',
  };

  const updateToRead = async (notification: any) => {
    if (notification && notification.isRead === false) {
      await dataProvider.update('inbox-notifications', { id: notification.id, data: { isRead: true }, previousData: notification });
      props.refreshNotifications();
    }
  }

  const handleItemClick = (notification: any) => {
    redirect(`chats/${notification.chatId}/show`);
    updateToRead(notification);
    props.handleClose();
  }

  const SimpleShow = (props) => {
    const { record, children, resource, basePath } = props
    return (
      <>
        {
          Children.map(children, field =>
            cloneElement(field, {
              record,
              resource,
              basePath,
            })
          )
        }
      </>
    );
  }

  return (
    <ListItemButton
      onClick={() => handleItemClick(notification)}
      selected={notification.isRead === false}
    >
      <ListItemAvatar style={{ minWidth: 45 }}>
        <Avatar className={classes.avatar}>
          {Icon[notification.type]}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={titleLabel[notification.type]}
        secondary={
          <>
            {
              notification.type === 'chat-message' &&
              <SimpleShow record={notification}>
                <ReferenceField reference="chat-messages" source="notificationId" label=" " link={false}>
                  <TitleField source="body" />
                </ReferenceField>
              </SimpleShow>
            }
            <div className={classes.containerTime}>
              <AccessTimeIcon style={{ fontSize: 14, marginRight: 2 }} />
              <Typography style={{ lineHeight: '14px' }} variant="caption">{moment(notification.createdAt).fromNow()}</Typography>
            </div>
          </>
        }
      />
    </ListItemButton>
  );
}

const NotificationPanel: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataProvider = useDataProvider();

  const [unreadTotalChatMessage, setunreadTotalChatMessage] = useStore('unreadTotalChatMessage', 0);

  const [notifications, setNotifications] = useState<any[]>([]);
  const [notificationTotal, setNotificationTotal] = useState<number>(0);
  const [onlyRead, setOnlyRead] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);

  const PER_PAGE = 15;

  useEffect(() => {
    refreshApi();

  }, [onlyRead]);

  useEffect(() => {
    fetchInboxNotification();
  }, [page]);

  const fetchInboxNotification = () => {

    const filter: any = {};
    if (onlyRead) { filter.isRead = false }

    dataProvider.getList('inbox-notifications', { filter, pagination: { perPage: PER_PAGE, page }, sort: { field: 'createdAt', order: 'DESC' } }).then((response: any) => {
      setNotifications(notifications => page > 1 ? [...notifications, ...response.data] : response.data);
      setNotificationTotal(response.unreadTotal);
      setunreadTotalChatMessage(response.unreadTotalChatMessage);

      setLastPage(Math.ceil(response.total / PER_PAGE));
    });
  };

  const refreshApi = useCallback(() => {
    if (page === 1) {
      fetchInboxNotification();
    } else {
      setPage(1);
      setLastPage(1);
    }
  }, [page, fetchInboxNotification]);

  const updateAllRead = async () => {
    dataProvider.update('inbox-notifications/all-read', { id: '', data: {} as RaRecord, previousData: {} as RaRecord })
      .finally(() => {
        refreshApi();
        handleClose();
      });
  }

  useActionNotify(fetchInboxNotification);

  const EmptyView = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '15px', height: '300px' }}>
      <InboxIcon sx={{ marginBottom: 1, fontSize: 70, color: PRIMARY_COLOR }} />
      <Typography variant="subtitle1" gutterBottom>
        Nenhuma notificação recebida
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
      <IconButton
        color="inherit"
        onClick={handleClick}
      >
        <Badge badgeContent={notificationTotal} color="info">
          <NotificationsIcon />
        </Badge>
      </IconButton>


      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            borderRadius: '8px',
            paddingBottom: '5px',
            width: '380px',
            maxWidth: '380px',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ padding: 2 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 1 }}>
              <Typography component="h6" variant="subtitle1" style={{ fontWeight: 'bold' }}>Notificações</Typography>
              <Typography component="p" variant="body2">{`Você tem ${notificationTotal} mensagens não lidas`}</Typography>
            </div>
            <Tooltip title={onlyRead ? 'Sem filtro' : 'Apenas não lidas'}>
              <IconButton onClick={() => setOnlyRead(!onlyRead)} sx={{ width: '35px', height: '35px', color: PRIMARY_COLOR }} color="primary" size="small">
                {
                  onlyRead ?
                    <FilterListOffIcon fontSize="small" />
                    :
                    <FilterListIcon fontSize="small" />
                }
              </IconButton>
            </Tooltip>
            <Tooltip title="Marcar todos como lidos">
              <IconButton
                onClick={updateAllRead}
                disabled={notificationTotal === 0}
                sx={{ width: '35px', height: '35px', color: PRIMARY_COLOR }}
                color="primary"
                size="small"
              >
                <DoneAllIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </Box>

        <Divider />

        <List
          sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'auto', maxHeight: 550 }}
          component="nav"
          subheader={
            <ListSubheader style={{ lineHeight: '35px' }} component="div">
              Caixa de entrada
            </ListSubheader>
          }
        >
          {notifications.length === 0 && <EmptyView />}
          {
            notifications.map((notification, index) => (
              <NotificationItem key={index} record={notification} handleClose={handleClose} refreshNotifications={refreshApi} />
            ))
          }

          {
            page < lastPage &&
            <Box sx={{ padding: 2 }}>
              <Button
                onClick={() => setPage(page => page + 1)}
                color="primary"
                variant="outlined"
                fullWidth
              >
                Visualizar mais
              </Button>
            </Box>
          }
        </List>
      </Popover>
    </Box>
  );
}

export default NotificationPanel;