import { formatCNPJ } from "@brazilian-utils/brazilian-utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Alert, AlertTitle, Box, Grid, ListItem, ListItemText, List as ListMui, Modal, Stack } from '@mui/material';
import { makeStyles, styled } from "@mui/styles";
import { uniqBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from 'react';
import {
  BooleanField, BooleanInput, Button, Create, CreateButton, DateField, DateTimeInput, DeleteButton, Edit, EditButton, Filter, FormDataConsumer, FunctionField, ImageField, ImageInput, NumberField, NumberInput, ReferenceArrayInput, ReferenceField, ReferenceInput,
  SaveButton,
  SelectArrayInput,
  SelectField,
  SelectInput, Show, SimpleForm, SimpleShowLayout, TextField, TextInput, Toolbar, TopToolbar,
  WrapperField,
  required,
  useDataProvider,
  useGetIdentity,
  useListContext, useNotify,
  useRecordContext, useRefresh,
  useShowContext,
  List,
} from "react-admin";
import { ApproveFillinButton, CnpjField, CustomizableDatagrid, CustomizableImageField, DownloadFileInvoice, FillinReviewButton, FillinStatusField, ImportInvoiceButton, LazyLoadAutoCompleteInput, Permission, Permissions, PriceInput } from '../components';
import CopyToChipboardField from "../components/CopyToChipboardField";
import { PERMISSIONS } from "../constants";
import { FillinAttemptStatus, FillinReviewStatus, FillinStatus } from "../models";
import { FillinPresetType } from "../models/fillinPresetType";
import { PaymentOrigin } from "../models/paymentOrigin";
import { UserRole } from "../providers/authProvider";
import { CNPJInput } from '../utils/Inputs';
import { formatCellphone, formatCpf, formatNumber, getChainId, getCompanyId, getStationId, linkDriveType } from "../utils/utils";
import ComponentWithPermissions from "./ComponentWithPermissions";
import { useRowClick } from "../hooks/useRowClick";

const editStyles = makeStyles({
  marginFilter: {
    marginLeft: '.25rem',
    marginBottom: '.5rem'
  },

  smallOdometer: {
    "& div": {
      width: 60,
      height: 100,
    },
    "& img": {
      width: "100%",
      height: "100%",
      cursor: "pointer",
    }
  },

  odometerModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 310,
    height: 550,
    "& img": {
      width: "100%",
      height: "100%"
    },
  }
});

export const ScreenType = {
  billShow: 'billShow',
}

const ListActions = () => (
  <TopToolbar>
    <Permissions permissions={[PERMISSIONS.CREATE_FILLINS]} >
      <CreateButton label="Adicionar manualmente" />
    </Permissions>
  </TopToolbar>
);

const OdometerField = (props) => {
  const { identity } = useGetIdentity();
  const record = useRecordContext(props);

  return record ? (
    <WrapperField>
      <NumberField {...props} />
      {identity && identity.role === UserRole.chainCashier &&
        <Button
          label={""}
          startIcon={<ContentCopyIcon />}
          onClick={(event) => {
            event.stopPropagation();
            copyToClipboard(
              event.currentTarget.parentElement.firstChild
                .textContent
            );
          }}
        />}
    </WrapperField>
  ) : null;
};

const FillinFilters = (props) => {
  const classes = editStyles();
  const { identity } = useGetIdentity();

  return (
    <Filter {...props}>
      {
        Permissions({
          userRoles: [UserRole.station],
          children: (
            <BooleanInput
              className={classes.marginFilter}
              source="isFinished"
              label="Mostrar abastecimentos baixados"
              defaultValue={false}
            />
          )
        })
      }
      <SelectInput
        label="Status"
        source="status"
        choices={FillinStatus.fillinStatusChoices}
        emptyText="Todos"
        alwaysOn
      />
      {
        !getStationId() &&
        <ReferenceArrayInput
          label="Posto"
          reference="stations"
          source="stationIds"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={false}
          filter={identity?.role === UserRole.company ? { companyId: getCompanyId() } : {}}
          alwaysOn
        >
          <SelectArrayInput optionText="name" label="Postos" />
        </ReferenceArrayInput>
      }
      {
        Permissions({
          userRoles: [UserRole.admin, UserRole.company],
          children: (
            <LazyLoadAutoCompleteInput
              label="Motorista"
              reference="drivers"
              source="driverId"
              format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
              style={{ minWidth: 260 }}
              size={"small"}
              alwaysOn
            />
          )
        })
      }
      {
        Permissions({
          userRoles: [UserRole.admin, UserRole.company],
          children: (
            <ReferenceInput source="chainId" reference="chains" textAlign="center" perPage={false} filter={identity?.role === UserRole.company ? { companyId: getCompanyId() } : {}} alwaysOn >
              <SelectInput optionText="name" label="Rede" emptyText="Todos" />
            </ReferenceInput>
          )
        })
      }
      {
        Permissions({
          userRoles: [UserRole.admin, UserRole.company],
          children: (
            <LazyLoadAutoCompleteInput
              label="Placa do Veículo"
              source="licensePlate"
              format={choice => choice.licensePlate}
              style={{ minWidth: 220 }}
              reference="vehicles"
              alwaysOn
            />
          )
        })
      }
    </Filter>
  )
}

const FillinList = (props) => {
  const { identity } = useGetIdentity();

  // let filterValue;
  // switch (identity?.role) {
  //   case UserRole.chain:
  //     filterValue = { chainId: getChainId() };
  //     break;
  //   case UserRole.company:
  //     filterValue = { companyId: getCompanyId() };
  //     break;
  //   case UserRole.chainCashier:
  //     filterValue = { chainId: getChainId(), stationId: getStationId() };
  //     break;
  //   default:
  //     filterValue = {}
  //     break;
  // }

  let defaultFilters = {};
  switch (identity?.role) {
    case UserRole.company:
      defaultFilters = { ...defaultFilters, status: FillinStatus.reproved };
      break;
    case UserRole.station:
      defaultFilters = { ...defaultFilters, isFinished: false };
      break;
  }

  return (
    <List {...props}
      sort={{ field: 'receivedAt', order: 'DESC' }}
      title="Abastecimentos"
      actions={<ListActions />}
      // filter={identity?.role === UserRole.chain ? { chainId: getChainId() } : identity?.role === UserRole.company ? { companyId: getCompanyId() } : identity?.role === UserRole.chainCashier ? { chainId: getChainId(), stationId: getStationId() } : {}}
      filters={<FillinFilters />}
      filterDefaultValues={defaultFilters}
      storeKey={null} // Disables storage, syncing only with the URL.
    >
      <FillinDatagrid rowClick="show" {...props} />
    </List>
  );
};

const InvoiceCnpjField = ({ label, ...props }) => {
  const record = useRecordContext();
  const { identity } = useGetIdentity();

  return (
    <CopyToChipboardField
      source="invoiceCnpj"
      label={label}
      showClipButton={identity && identity.role === UserRole.station && record.status === FillinStatus.approved}
      textAlign="center"
      Component={CnpjField}
      isHidden={record.status !== FillinStatus.approved || record.stationReviewStatus !== FillinReviewStatus.approved}
      {...props}
    />
  );
}

export const FillinDatagrid = ({ disableBulkActionButtons = false, screen = '', ...props }) => {
  const [vehicleBases, setVehicleBases] = useState();
  const [station, setStation] = useState();
  const { handleRowClick } = useRowClick();

  const { data } = useListContext();
  const dataProvider = useDataProvider();
  const invoiceBaseIds = uniqBy(data?.map((fillin) => fillin.invoiceBaseId));
  const stationIds = uniqBy(data?.map((fillin) => fillin.stationId));
  const { identity } = useGetIdentity();

  const FinishFillinButton = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const refresh = useRefresh();
    const notify = useNotify();

    const doUpdate = (successMessage, errorMessage) => {
      dataProvider.update('fillins/finish', {
        id: record.id,
        previousData: record
      })
        .then(() => {
          notify(successMessage);
          refresh();
        })
        .catch(() => { notify(errorMessage, 'warning'); });
    }

    const successMessage = 'Abastecimento baixado com sucesso!';
    const errorMessage = 'Erro ao dar baixa no abastecimento.';

    return (
      !record.isFinished && record.status === FillinStatus.approved && record.stationReviewStatus === FillinReviewStatus.approved
        ? <Button
          sx={{ textWrap: 'nowrap' }}
          label="Dar baixa"
          onClick={(e) => {
            doUpdate(
              successMessage,
              errorMessage,
            )
            e.stopPropagation();
          }}
          variant="outlined"
        />
        : null
    );
  }

  const approvedRowStyle = (record) => ({
    backgroundColor: record.stationReviewStatus === FillinReviewStatus.pending ? '#FFF59D' : record.status === FillinStatus.reproved || record.stationReviewStatus === FillinReviewStatus.denied ? '#FFD1D9' : '#white',
    '& .MuiTableCell-root': {
      borderColor: 'rgb(190, 190, 190)',
      ...(record.stationReviewStatus === FillinReviewStatus.pending || record.status === FillinStatus.reproved ? { paddingTop: 2.5, paddingBottom: 2.5 } : {}),
    },
    '& .RaReferenceField-link > span, & .RaReferenceField-link > div, & .MuiButton-text': { color: '#3B9EFF' },
    // With green color for approved
    // backgroundColor : record.status === 'reproved' ? '#FFEBE6' : '#E3FDEE'
  });

  useEffect(() => {
    if (identity && identity.role === UserRole.station) {
      if (invoiceBaseIds?.length > 0) {
        dataProvider.getMany('vehicle-bases', {
          ids: invoiceBaseIds,
        }).then(({ data }) =>
          setVehicleBases(data.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}))
        );
      }
      if (stationIds?.length > 0) {
        dataProvider.getMany('stations', {
          ids: stationIds,
        }).then(({ data }) =>
          setStation(data.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}))
        );
      }
    }
  }, [JSON.stringify(invoiceBaseIds), JSON.stringify(stationIds)]);

  return (
    <CustomizableDatagrid
      {...props}
      rowClick={(...rest) => props.rowClick !== undefined ? handleRowClick(props.rowClick, ...rest) : identity && handleRowClick('show', ...rest)}
      resource="fillins"
      rowSx={approvedRowStyle}
      bulkActionButtons={false}
    >
      {
        props.bulkActions && props.bulkActions
      }
      {
        screen !== ScreenType.billShow &&
        <FillinStatusField label="Status" />
      }
      {
        screen !== ScreenType.billShow &&
        Permissions({
          permissions: [PERMISSIONS.APPROVE_FILLINS],
          userRoles: [UserRole.company],
          children: (
            <ApproveFillinButton label="Aprovar" />
          )
        })
      }
      {
        screen !== ScreenType.billShow &&
        Permissions({
          userRoles: [UserRole.company, UserRole.admin],
          children: (
            <FunctionField
              label="Média do Abastecimento"
              render={(record) => {
                return record.lastOdometer ? formatNumber((record.odometer - record.lastOdometer) / record.amount, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-';
              }}
            />
          )
        })
      }
      {
        screen !== ScreenType.billShow &&
        Permissions({
          userRoles: [UserRole.company, UserRole.admin],
          children: (
            <FunctionField
              label="Média (Limites)"
              render={(record) => {
                return `${record.consumptionAverageBottomLimit || '-'}/${record.consumptionAverageTopLimit || '-'}`;
              }}
            />
          )
        })
      }
      {
        Permissions({
          userRoles: [UserRole.chain, UserRole.admin, UserRole.company],
          children: (
            <WrapperField label="Posto" textAlign="center">
              <ReferenceField
                reference="stations"
                source="stationId"
                textAlign="center"
                link={identity && [UserRole.admin, UserRole.chain].includes(identity.role) ? "show" : false}
              >
                <TextField source="name" />
              </ReferenceField>
              <FunctionField render={(record) => record && record.paymentOrigin ? ` - ${PaymentOrigin.translateOrigin(record.paymentOrigin)}` : ''} />
            </WrapperField>
          )
        })
      }
      <ReferenceField
        label="Combustível"
        reference="fuels"
        source="fuelId"
        textAlign="center"
        link={identity?.role === UserRole.admin ? "show" : false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Veículo"
        reference="vehicles"
        source="vehicleId"
        textAlign="center"
        link={identity && [UserRole.admin, UserRole.company].includes(identity.role) ? "show" : false}
      >
        <CopyToChipboardField source="licensePlate" />
      </ReferenceField>
      {
        Permissions({
          userRoles: [UserRole.admin, UserRole.chain, UserRole.station],
          children: (
            <NumberField source="hoseNumber" label="Bico" textAlign="center" emptyText='Não Informado' />
          )
        })
      }
      <NumberField source="amount" label="Quantidade em litros" locales="pt-BR" textAlign="center" />
      <NumberField source="value" label="Valor pago" locales="pt-BR" options={{ style: 'currency', currency: 'BRL' }} textAlign="center" />
      {
        screen !== ScreenType.billShow &&
        <CopyToChipboardField
          Component={NumberField}
          source="odometer"
          label="Odômetro"
          locales="pt-BR"
          textAlign="center"
          showClipButton={identity && identity.role === UserRole.station}
        />
      }
      <DateField source="receivedAt" label="Data inclusão" showTime locales="pt-BR" textAlign="center" />
      {
        screen !== ScreenType.billShow &&
        <BooleanField source="manuallyAdded" label="Manual?" />
      }
      {
        screen !== ScreenType.billShow &&
        <FunctionField source="deliveryId" label="Autorização" render={record => record.status === FillinStatus.approved && record.stationReviewStatus === FillinReviewStatus.approved ? record.numericId : "Indisponível"} />
      }
      {
        screen !== ScreenType.billShow &&
        Permissions({
          userRoles: [UserRole.admin, UserRole.chain, UserRole.station],
          children: (
            <InvoiceCnpjField label="CNPJ da nota" />
          )
        })
      }
      {
        screen !== ScreenType.billShow &&
        <ReferenceField
          label="Aprovado Por"
          emptyText="--"
          source="approvedBy"
          basePath="company-employees"
          reference="company-employees"
          link={identity && [UserRole.admin, UserRole.company].includes(identity.role) ? "edit" : false}
        >
          <TextField source="name" />
        </ReferenceField>
      }
      {
        Permissions({
          userRoles: [UserRole.admin, UserRole.chain],
          children: (
            <ReferenceField source="invoiceId" reference="invoices" emptyText="--" label="Nota Fiscal" textAlign="center" link={false}>
              <DownloadFileInvoice />
            </ReferenceField>
          )
        })
      }
      {
        (screen !== ScreenType.billShow && identity && [UserRole.admin, UserRole.company].includes(identity.role)) &&
        <DateField source="approvedAt" label="Aprovado em" emptyText="--" showTime locales="pt-BR" />
      }
      {
        Permissions({
          userRoles: [UserRole.station],
          children: (
            <FunctionField
              label="Aviso"
              render={(record) => {
                const vehicleBasesMustInvoice = vehicleBases ? vehicleBases[record.invoiceBaseId]?.mustIssueInvoice : null;
                const stationsMustInvoice = station ? station[record.stationId]?.mustIssueInvoice : null;

                if (vehicleBasesMustInvoice || stationsMustInvoice) {
                  return (
                    "A NFe deve ser emitida imediatamente"
                  );
                }
              }}
            />
          )
        })
      }
      {
        (
          screen !== ScreenType.billShow ||
          (screen !== ScreenType.billShow && identity && identity.role === UserRole.station) ||
          props.actions
        ) &&
        <Stack flexDirection="row" gap={2} label="Ações">
          {
            screen !== ScreenType.billShow &&
            Permissions({
              userRoles: [UserRole.station],
              children: (
                <FillinReviewButton source="stationReviewStatus" />
              )
            })
          }
          {
            (screen !== ScreenType.billShow && identity && identity.role === UserRole.station) &&
            <FinishFillinButton />
          }
          {
            props.actions && props.actions
          }
        </Stack>
      }
      <ReferenceField source="billId" reference="bills" emptyText="--" label="Fatura" textAlign="center" link={"show"}>
        <TextField source="numericId" />
      </ReferenceField>
    </CustomizableDatagrid>
  );
};

const PresetValueField = ({ source }) => {
  const record = useRecordContext();

  return (
    record.presetType === FillinPresetType.cash ?
      <NumberField source={source} locales="pt-BR" options={{ style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }} />
      :
      <NumberField source={source} locales="pt-BR" emptyText="--" />
  );
}

const FillinShow = props => {
  const { identity } = useGetIdentity();
  const [showOdometer, setShowOdometer] = useState(false);
  const classes = editStyles();

  const openModal = (evt) => {
    if (evt.target.tagName === "IMG") {
      setShowOdometer(true);
    }
  };

  const closeModal = () => {
    setShowOdometer(false);
  };

  const Messagefield = () => {
    const record = useRecordContext();
    const [vehicleBases, setVehicleBases] = useState({});
    const [station, setStation] = useState({});
    const dataProvider = useDataProvider();

    useEffect(() => {
      if (record.invoiceBaseId) {
        dataProvider.getMany('vehicle-bases', { ids: [record.invoiceBaseId] }).then(({ data }) => {
          setVehicleBases(data[0]);
        });
      }
      if (record.stationId) {
        dataProvider.getMany('stations', { ids: [record.stationId] }).then(({ data }) => {
          setStation(data[0]);
        });
      }
    }, [dataProvider, record.invoiceBaseId, record.stationId]);

    if (vehicleBases.mustIssueInvoice || station.mustIssueInvoice) {
      return (
        "A NFe deve ser emitida imediatamente"
      );
    }
  };

  const FillinInfo = () => {
    const record = useRecordContext();
    return (
      <SimpleShowLayout>
        <Grid container width={{ xs: '100%', xl: 1200 }} spacing={2}>
          <Grid item xs={12} md={6}>
            <Box component="fieldset" sx={{ border: '1px dashed grey' }}>
              <Legend>Detalhes do abastecimento</Legend>
              <SimpleShowLayout style={{ padding: 0 }}>
                <TextField source="id" label="ID" />
                {
                  Permission({
                    userRole: UserRole.admin,
                    children: (
                      <TextField source="reserveId" label="ID da reserva CTT" />
                    )
                  })
                }
                <ReferenceField
                  label="Combustível"
                  reference="fuels"
                  source="fuelId"
                  link={identity?.role === UserRole.admin}
                >
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="contractId" reference="contracts" label="Código do contrato" textAlign="center" emptyText="--" link={false}>
                  <TextField source="code" />
                </ReferenceField>
                <NumberField source="amount" label="Quantidade em litros" locales="pt-BR" />
                {
                  Permissions({
                    userRoles: [UserRole.company, UserRole.admin],
                    children: (
                      <FunctionField
                        label="Média do Abastecimento"
                        render={(record) => {
                          return record.lastOdometer ? formatNumber((record.odometer - record.lastOdometer) / record.amount, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-';
                        }}
                      />
                    )
                  })
                }
                {!record?.manuallyAdded &&
                  <NumberField source="oldPricePerLiter" label="Preço por litro - Bomba" locales="pt-BR" emptyText="--" options={{ style: 'currency', currency: 'BRL', minimumFractionDigits: 3 }} />
                }
                <NumberField source="pricePerLiter" label="Preço por litro - Acordo" locales="pt-BR" options={{ style: 'currency', currency: 'BRL', minimumFractionDigits: 3 }} />
                <NumberField source="value" label="Valor pago" locales="pt-BR" options={{ style: 'currency', currency: 'BRL' }} />
                <NumberField source="hoseNumber" label="Bico" emptyText="--" />
                <BooleanField source="fillingCompletely" label="Encheu o tanque?" />
                <BooleanField source="manuallyAdded" label="Abastecimento manual?" />
                <ReferenceField source="invoiceId" label="Nota Fiscal" reference="invoices" emptyText="--" link={false}>
                  <DownloadFileInvoice />
                </ReferenceField>
                <DateField source="receivedAt" label="Data inclusão" showTime locales="pt-BR" />
                {
                  Permissions({
                    userRoles: [UserRole.admin, UserRole.chain, UserRole.station],
                    children: (
                      <InvoiceCnpjField label="CNPJ da nota" emptyText='--' />
                    )
                  })
                }
                <FillinStatusField label="Status" />
                <TextField label="Aprovado por" source="approvedBy" emptyText="--" />
                <ReferenceField source="billId" reference="bills" emptyText="--" label="Fatura" textAlign="center" link={"show"}>
                  <TextField source="numericId" />
                </ReferenceField>
              </SimpleShowLayout>
            </Box>
            <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
              <Legend>Preset selecionado</Legend>
              <SimpleShowLayout style={{ padding: 0 }}>
                <ReferenceField
                  label="Combustível"
                  reference="fuels"
                  source="fuelId"
                  link={identity?.role === UserRole.admin}
                >
                  <TextField source="name" />
                </ReferenceField>
                <SelectField source="presetType" label="Limite" choices={FillinPresetType.fillinPresetTypeChoices} />
                {
                  record?.presetType !== FillinPresetType.completely ?
                    <PresetValueField source="presetValue" label="Valor" />
                    :
                    <></>
                }
              </SimpleShowLayout>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box component="fieldset" sx={{ border: '1px dashed grey' }}>
              <Legend>Detalhes do veículo</Legend>
              <SimpleShowLayout style={{ padding: 0 }}>
                <ReferenceField
                  source="subCompanyId"
                  label="Transportadora"
                  reference="sub-companies"
                  emptyText="Não definida"
                  link={identity && [UserRole.admin, UserRole.company].includes(identity.role) ? 'show' : null}
                >
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                  source="subCompanyId"
                  label="CNPJ"
                  reference="sub-companies"
                  emptyText="Não definido"
                  link={identity && [UserRole.admin, UserRole.company].includes(identity.role) ? 'show' : null}
                >
                  <FunctionField render={record => record && formatCNPJ(record.cnpj)} />
                </ReferenceField>
                {
                  identity && identity.role === UserRole.station && (
                    <ReferenceField
                      source="subCompanyId"
                      label="Telefone da Transportadora"
                      reference="sub-companies"
                      emptyText="Não definido"
                      link={identity && [UserRole.admin, UserRole.company].includes(identity.role) ? 'show' : null}
                    >
                      <FunctionField render={record => formatCellphone(record.phone)} />
                    </ReferenceField>
                  )
                }
                {
                  Permissions({
                    permissions: [PERMISSIONS.LIST_DRIVERS],
                    children: (
                      <ReferenceField
                        label="Motorista"
                        reference="drivers"
                        source="driverId"
                        link={identity && [UserRole.admin, UserRole.company].includes(identity.role) ? linkDriveType : false}
                      >
                        <TextField source="name" />
                      </ReferenceField>
                    )
                  })
                }
                {identity && [UserRole.admin, UserRole.company].includes(identity.role) &&
                  <ReferenceField
                    label="Manobrista"
                    reference="drivers"
                    source="valetId"
                    emptyText="Sem manobrista"
                    link={identity && [UserRole.admin, UserRole.company].includes(identity.role) ? linkDriveType : false}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                }
                <ReferenceField
                  label="Placa"
                  reference="vehicles"
                  source="vehicleId"
                  link={(identity && [UserRole.admin, UserRole.company].includes(identity.role)) ? 'show' : false}
                >
                  <CopyToChipboardField source="licensePlate" />
                </ReferenceField>
                <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
                  <Legend>Odômetros</Legend>
                  <SimpleShowLayout style={{ padding: 0 }}>
                    <NumberField source="odometer" label="Odômetro Digitado" locales="pt-BR" />
                    <NumberField source="odometerTrucksControl" label="Odômetro da Telemetria" locales="pt-BR" emptyText="-" />
                    <NumberField source="dateInicioTrucksControl" label="Data da Telemetria" locales="pt-BR" emptyText="-" />
                    <NumberField source="lastOdometer" label="Odômetro Anterior" locales="pt-BR" emptyText="-" />
                  </SimpleShowLayout>
                </Box>
              </SimpleShowLayout>
            </Box>
            <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
              <Legend>Local</Legend>
              <SimpleShowLayout style={{ padding: 0 }}>
                {identity && [UserRole.admin, UserRole.company].includes(identity.role) &&
                  <ReferenceField
                    label="Rede"
                    source="chainId"
                    emptyText="Não identificada"
                    reference="chains"
                    link={identity?.role === UserRole.admin}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                }
                <ReferenceField
                  label="Posto"
                  reference="stations"
                  source="stationId"
                  link={(identity && [UserRole.admin, UserRole.chain].includes(identity.role)) ? "show" : false}
                >
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                  label="Frentista"
                  reference="station-attendants"
                  source="attendantId"
                  emptyText="Não encontrado"
                >
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                  label="Adicionado por"
                  source="createdBy"
                  reference="employees"
                  emptyText="--"
                >
                  <TextField source="name" />
                </ReferenceField>
              </SimpleShowLayout>
            </Box>
            <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
              <Legend>Imagens</Legend>
              <SimpleShowLayout style={{ padding: 0 }}>
                <CustomizableImageField
                  source="odometerPath"
                  label="Imagem Odômetro (Clique para ampliar)"
                  emptyText='Nenhuma imagem encontrada.'
                  onClick={(evt) => openModal(evt)}
                  className={classes.smallOdometer}
                />
                <Modal
                  open={showOdometer}
                  onClose={closeModal}
                  aria-labelledby="Bigger Odometer Modal"
                  aria-describedby="A modal that shows a bigger photo of the odometer."
                >
                  <CustomizableImageField
                    source="odometerPath"
                    label="Imagem Odômetro"
                    className={classes.odometerModal}
                  />
                </Modal>
                <ImageField
                  source="invoicePath"
                  label="Imagem Nota Fiscal"
                  emptyText='Nenhuma imagem encontrada.'
                />
              </SimpleShowLayout>
            </Box>
            {record && (!record.generateFinancialMovement || record.status === FillinStatus.reproved || [FillinReviewStatus.pending, FillinReviewStatus.denied].includes(record.stationReviewStatus))
              &&
              <Alert variant="outlined" severity="info" sx={{ marginTop: 2 }}>
                <AlertTitle>Não será possível importar uma nota para esse abastecimento pelo(s) motivo(s):</AlertTitle>
                <ListMui>
                  {
                    !record.generateFinancialMovement &&
                    <ListItem disablePadding>
                      <ListItemText
                        primary="Não gera transação financeira"
                      />
                    </ListItem>
                  }
                  {
                    record.status === FillinStatus.reproved &&
                    <ListItem disablePadding>
                      <ListItemText
                        primary="Reprovado pelo sistema"
                      />
                    </ListItem>
                  }
                  {
                    [FillinReviewStatus.pending, FillinReviewStatus.denied].includes(record.stationReviewStatus) &&
                    <ListItem disablePadding>
                      <ListItemText
                        primary="Não aprovado pelo caixa"
                      />
                    </ListItem>
                  }
                </ListMui>
              </Alert>
            }
            {
              identity?.role === UserRole.station &&
              <Box component="fieldset" sx={{ border: '1px dashed grey', marginTop: 2 }}>
                <Legend>Avisos</Legend>
                <Messagefield />
              </Box>
            }
          </Grid>
        </Grid>
      </SimpleShowLayout>
    );
  }

  const ShowActions = (props) => (
    <TopToolbar {...props}>
      <Permission permission={PERMISSIONS.CREATE_INVOICE}>
        <ImportInvoiceButton />
      </Permission>
      <Permission permission={PERMISSIONS.CREATE_FILLINS}>
        <EditButton />
      </Permission>
    </TopToolbar>
  );

  return (
    <Show
      {...props}
      title="Abastecimento"
      actions={<ShowActions />}
    >
      <FillinInfo />
    </Show>
  );
};

const FillinCreate = props => {
  const { identity } = useGetIdentity();
  return (
    <Create
      {...props}
      title='Cadastrar abastecimento'
      transform={data => {
        const { cnpj, corporateName, ...rest } = data;
        if (data.stationId) { return rest; }
        return data;
      }}
    >
      <SimpleForm sanitizeEmptyValues defaultValues={{ employeeId: identity && identity.role === UserRole.admin ? null : localStorage.getItem('id') }}>
        <ReferenceInput
          label="Transportadora"
          source="subCompanyId"
          reference="sub-companies"
          filter={identity && identity.role === UserRole.company ? { companyId: getCompanyId() } : {}}
          sort={{ field: "name", order: "ASC" }}
          validate={required()}
          perPage={false}
        >
          <SelectInput label="Transportadora" optionText="name" />
        </ReferenceInput>
        <SelectInput
          label="Pagamento"
          choices={PaymentOrigin.paymentOriginChoices}
          source="paymentOrigin"
        />
        <LazyLoadAutoCompleteInput
          label="Placa do Veículo"
          source="licensePlate"
          format={choice => choice.licensePlate}
          style={{ minWidth: 160 }}
          reference="vehicles"
        />
        <ReferenceInput
          reference="drivers"
          source="valetId"
          filter={{ isValet: true, ...(identity?.role === UserRole.company ? { companyId: getCompanyId() } : {}) }}
          sort={{ field: 'name', order: 'ASC' }}
          emptyText="Selecione um manobrista"
          pagination={null}
          perPage={null}
          allowEmpty
        >
          <SelectInput label="Manobrista" optionText="name" />
        </ReferenceInput>
        <LazyLoadAutoCompleteInput
          label="Motorista"
          reference="drivers"
          source="driverId"
          format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
          style={{ minWidth: 400 }}
          size={"small"}
        />
        <ReferenceInput
          reference="fuels"
          source="fuelId"
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput label="Combustível" optionText="name" validate={required()} isRequired />
        </ReferenceInput>
        <ReferenceInput
          source="stationId"
          reference={identity?.role === UserRole.company ? "companies/companyId/stations" : "stations"}
          filter={identity?.role === UserRole.chain ? { chainId: getChainId() } : {}}
          sort={{ field: "name", order: "ASC" }}
          perPage={false}
        >
          <SelectInput label="Posto" optionText="name" defaultValue={identity?.role === UserRole.station ? getStationId() : undefined} disabled={identity?.role === UserRole.station} />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => (
            !formData.stationId ? <CNPJInput source="cnpj" label="CNPJ" disabled={!!formData.stationId} style={{ width: 258 }} /> : null
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => (
            !formData.stationId ? <TextInput source="corporateName" label="Razão Social" disabled={!!formData.stationId} style={{ width: 258 }} /> : null
          )}
        </FormDataConsumer>
        <PriceInput source="pricePerLiter" label="Preço por litro - Acordo" precision="3" locales="pt-BR" validate={required()} />
        <PriceInput source="oldPricePerLiter" label="Preço por litro - Bomba" precision="3" locales="pt-BR" validate={required()} />
        <NumberInput source="amount" label="Quantidade em litros" locales="pt-BR" validate={required()} />
        <PriceInput source="value" label="Valor total" locales="pt-BR" validate={required()} />
        <NumberInput source="odometer" label="Odômetro" locales="pt-BR" validate={required()} />
        <NumberInput source="lastOdometer" label="Odômetro Anterior" locales="pt-BR" />
        <DateTimeInput source="createdAt" label="Data do concentrador" locales="pt-BR" parse={(value) => moment(value).toISOString()} validate={required()} />
        <DateTimeInput source="receivedAt" label="Data de recebimento" locales="pt-BR" parse={(value) => moment(value).toISOString()} validate={required()} />
        <BooleanInput source="fillingCompletely" label="Encheu o tanque?" />
        <TextInput source="reasonForAddition" label="Justificativa" validate={required()} />
        <BooleanInput source="generateFinancialMovement" label="Gerar movimentação financeira?" defaultValue={false} />
      </SimpleForm>
    </Create>
  );
};

const ToolBarCustom = () => (
  <Toolbar style={{ justifyContent: 'space-between' }}>
    <SaveButton />
    <Permission permission={PERMISSIONS.ADMIN} userRole={UserRole.admin}>
      <DeleteButton />
    </Permission>
  </Toolbar>
);

const FillinEdit = props => {
  const { identity } = useGetIdentity();

  const disabled = !identity || identity?.role !== UserRole.admin;

  return (
    <Edit title='Editar abastecimento' {...props}
      transform={data => {
        delete data.pumpPrices;
        return data;
      }}>
      <SimpleForm toolbar={<ToolBarCustom />}>
        <TextInput disabled label="ID" source="id" />
        <ReferenceInput
          label="Transportadora"
          source="subCompanyId"
          reference="sub-companies"
          filter={{ companyId: getCompanyId() }}
          sort={{ field: "name", order: "ASC" }}
          validate={required()}>
          <SelectInput disabled label="Transportadora" optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Manobrista"
          reference="drivers"
          source="valetId"
          filter={{ isValet: true }}
          sort={{ field: 'name', order: 'ASC' }}
          emptyText="Selecione um manobrista"
          allowEmpty
        >
          <SelectInput disabled label="Manobrista" optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => (
            <LazyLoadAutoCompleteInput
              label="Motorista"
              reference="drivers"
              source="driverId"
              format={choice => `${formatCpf(choice.cpf)} ${choice.register ? '- ' + choice.register : ''} - ${choice.name}`}
              style={{ minWidth: 400, marginBottom: 24 }}
              size={"small"}
              disabled
              record={formData}
            />
          )}
        </FormDataConsumer>
        <NumberInput disabled={disabled} source="amount" label="Quantidade em litros" locales="pt-BR" validate={required()} />
        <NumberInput source="odometer" label="Odômetro" locales="pt-BR" />
        <PriceInput disabled={disabled} source="value" label="Valor pago" locales="pt-BR" validate={required()} />
        <ReferenceInput
          source="fuelId"
          reference="fuels"
          sort={{ field: 'name', order: 'ASC' }}
          validate={required()}
        >
          <SelectInput disabled label="Combustível" optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => (
            <LazyLoadAutoCompleteInput
              label="Veículo"
              source="vehicleId"
              disabled
              format={choice => choice.licensePlate}
              style={{ minWidth: 160, marginBottom: 24 }}
              reference="vehicles"
              record={formData} />
          )}
        </FormDataConsumer>
        <PriceInput precision="3" disabled={disabled} source="oldPricePerLiter" label="Preço por litro - Bomba" locales="pt-BR" />
        <PriceInput precision="3" disabled={disabled} source="pricePerLiter" label="Preço por litro - Acordo" locales="pt-BR" validate={required()} />
        <NumberInput source="encerrante" label="Encerrante" locales="pt-BR" />
        <NumberInput source="hoseNumber" label="Bico" locales="pt-BR" />
        <TextInput disabled source="reasonForAddition" label="Justificativa" />
        <BooleanInput disabled={disabled} source="generateFinancialMovement" label="Gerar movimentação financeira?" defaultValue={false} />
        <Permissions userRoles={[UserRole.company]} permissions={[PERMISSIONS.APPROVE_FILLINS]}>
          <ApproveFillinButton style={{ marginBottom: 30 }} label="Aprovar abastecimento" />
        </Permissions>
        {
          Permission({
            permission: PERMISSIONS.ADMIN,
            userRole: UserRole.admin,
            children: (
              <SelectInput
                source="status"
                label="Status (Sistema)"
                choices={FillinStatus.fillinStatusChoices}
                sx={{ minWidth: 220 }}
                isRequired
              />
            )
          })
        }
        {
          Permission({
            permission: PERMISSIONS.ADMIN,
            userRole: UserRole.admin,
            children: (
              <SelectInput
                source="stationReviewStatus"
                label="Status (Caixa)"
                choices={FillinReviewStatus.fillinReviewStatusChoices}
                sx={{ minWidth: 220 }}
                isRequired
              />
            )
          })
        }
        <DateTimeInput
          source="createdAt"
          label="Data de recebimento"
          locales="pt-BR"
          parse={(value) => moment(value).toISOString()}
          validate={required()}
          disabled={disabled}
        />
        <DateTimeInput
          source="receivedAt"
          label="Data do concentrador"
          locales="pt-BR"
          parse={(value) => moment(value).toISOString()}
          validate={required()}
          disabled={disabled}
        />
        <CNPJInput source="invoiceCnpj" label="CNPJ da Nota" disabled={disabled} />
        <BooleanInput source="fillingCompletely" label="Encheu o tanque?" />
        <ImageInput disabled source="odometerFile" label="Imagem Odômetro" accept="image/*">
          <ImageField source="src" label="Imagem" />
        </ImageInput>
        <ImageInput disabled source="invoiceFile" label="Imagem Nota Fiscal" accept="image/*">
          <ImageField source="src" label="Imagem" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

const Legend = styled('legend')({
  paddingLeft: 12,
  paddingRight: 12,
});

const resource = {
  list: FillinList,
  edit: new ComponentWithPermissions({ component: FillinEdit, permissions: [PERMISSIONS.CREATE_FILLINS] }),
  create: new ComponentWithPermissions({ component: FillinCreate, permissions: [PERMISSIONS.CREATE_FILLINS] }),
  show: FillinShow,
  name: 'fillins',
  permissions: [PERMISSIONS.LIST_FILLINS, PERMISSIONS.APPROVE_FILLINS]
};

export default resource;
