import { makeStyles } from '@mui/styles';
import { BooleanField, BooleanInput, ChipField, Datagrid, DateField, DateInput, Filter, FunctionField, NumberField, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectInput, SingleFieldList, TextField, TextInput, useGetIdentity, useListContext } from 'react-admin';
import { LazyLoadAutoCompleteInput, ListPagination, Permission, ReportList } from '../../components';
import { UserRole } from '../../providers/authProvider';
import { formatPrice, getChainId, isDifferenceSignificant } from '../../utils/utils';
import dayjs from 'dayjs';
import { useRowClick } from '../../hooks/useRowClick';
import { PERMISSIONS } from '../../constants';
import { Box } from '@mui/material';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold',
    paddingTop: 30
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
}));

const conciliationRowStyle = (record) => ({
  ...((record?.invoice_id && isDifferenceSignificant(record.total_invoice_value, record.value)) || (isDifferenceSignificant(+record.value, +record.contract_value)) ? {
    backgroundColor: 'rgba(188, 55, 55, 0.5)'
  } : {}),
});

const ListFilters = () => {
  const identity = useGetIdentity();
  const { filterValues } = useListContext();
  return (
    <Filter>
      <DateInput source="from" label="De" options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => dayjs(value).startOf('day').toISOString()} />
      <DateInput source="to" label="Até" options={{ format: 'DD/MM/YYYY', disableFuture: true }} alwaysOn parse={(value) => dayjs(value).endOf('day').toISOString()} />
      <ReferenceInput
        label="Rede"
        source="chainId"
        reference="chains"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
        alwaysOn
      >
        <SelectInput
          emptyText="Todos"
          label="Rede"
          optionText={'name'}
        />
      </ReferenceInput>

      <ReferenceArrayInput
        source="stationIds"
        reference="stations"
        sort={{ field: "name", order: "ASC" }}
        filter={identity?.role === UserRole.chain ? { chainId: getChainId() } : { chainId: filterValues.chainId || undefined }}
        alwaysOn
        perPage={null}
      >
        <SelectArrayInput
          label="Posto"
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Produto" source="fuelIds" reference="fuels" sort={{ field: "name", order: "ASC" }} emptyText="Todos" alwaysOn>
        <SelectArrayInput
          label="Produto"
          optionText={'name'}
        />
      </ReferenceArrayInput>
      <LazyLoadAutoCompleteInput
        label="Placa do Veículo"
        source="licensePlate"
        format={choice => choice.licensePlate}
        style={{ minWidth: 220 }}
        reference="vehicles"
        alwaysOn
      />
      <TextInput source="search" label="Nº da nota" alwaysOn />
      <Box sx={{ display: 'flex', alignSelf: "center" }} alwaysOn>
        <BooleanInput source="isDiffFillin" label="Abast. com diferença" alwaysOn />
      </Box>
    </Filter>
  );
}

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={[{ ...filterValues, generatedReportAt: new Date() }]} total={1} bulkActionButtons={false}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <ReferenceField
        source="chainId"
        label="Rede"
        link={false}
        reference="chains"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField label="Posto" emptyText="Todos" source="stationIds" textAlign="center" basePath="stations" reference="stations" link={false}>
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        source="fuelIds"
        reference="fuels"
        link={false}
        label="Produto"
      >
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="licensePlate" label="Placa do Veículo" />
      <TextField source="search" label="Nº da nota" />
      <BooleanField source="isDiffFillin" label="Abast. com diferença" />
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const ConciliationList = () => {
  const { handleRowClick } = useRowClick();
  const identity = useGetIdentity();

  return (
    <Datagrid
      rowClick={(id, resource) => handleRowClick('show', id, resource)}
      bulkActionButtons={false}
      resource="fillins"
      expand={false}
      rowSx={conciliationRowStyle}
    >
      <DateField source="received_at" label="Data" showTime locales="pt-BR" />
      <ReferenceField label="Veículo" emptyText="Não identificado" source="vehicle_id" basePath="vehicles" reference="vehicles" link="show">
        <TextField label="Veículo" emptyText="Não identificado" source="licensePlate" />
      </ReferenceField>
      <ReferenceField
        label="Posto"
        emptyText="Posto não identificado"
        source="station_id"
        basePath="stations"
        reference="stations"
      >
        <TextField
          source="name" />
      </ReferenceField>
      <ReferenceField
        label="Produto"
        emptyText="Não identificado"
        source="fuel_id"
        basePath="fuels"
        reference="fuels"
        link={identity?.role === UserRole.admin && "show"}
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField
        source="amount"
        label="Litros"
        textAlign="center"
        emptyText="0"
        options={{
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }}
      />
      <ReferenceField source="contract_id" reference="contracts" label="Contrato" textAlign="center" emptyText="--" link={false}>
        <TextField source="code" />
      </ReferenceField>
      <NumberField
        source="value"
        label="Valor (FleetX)"
        locales="pt-BR"
        textAlign="center"
        emptyText="R$ 0,000"
        options={{
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }}
      />
      <NumberField
        source="total_invoice_value"
        label="Valor (Nota)"
        emptyText="R$ 0,000"
        locales="pt-BR"
        textAlign="center"
        sortable={false}
        options={{
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }}
      />
      <NumberField
        source="contract_value"
        label="Valor (Acordo)"
        emptyText="R$ 0,000"
        locales="pt-BR"
        textAlign="center"
        sortable={false}
        options={{
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }}
      />
      <FunctionField
        label="Diferença"
        textAlign="center"
        render={(record) => {
          const diff = record.total_invoice_value - Number(record.contract_value);
          return formatPrice(Math.abs(diff));
        }}
      />
    </Datagrid>
  )
}

const conciliationExporter = async (data, fetchRelatedRecords) => {
  let conciliationInfo = data;

  const relations = [
    { field: 'fuel_id', resource: 'fuels' },
    { field: 'contract_id', resource: 'contracts' },
    { field: 'station_id', resource: 'stations' },
    { field: 'vehicle_id', resource: 'vehicles' },
  ];

  const fetchData = await fetchRelatedRecords(relations, conciliationInfo);

  const getData = (resource, dataResult) => {
    return dataResult.find(({ resource: res }) => res === resource)?.results || [];
  };

  conciliationInfo = conciliationInfo.map(fillin => {
    const { id, vehicle_id, fuel_id, station_id, contract_id, value, amount, received_at, total_invoice_value, contract_value } = fillin;
    const vehicle = getData('vehicles', fetchData).find(({ id }) => id === vehicle_id);
    const station = getData('stations', fetchData).find(({ id }) => id === station_id);
    const fuel = getData('fuels', fetchData).find(({ id }) => id === fuel_id);
    const contract = getData('contracts', fetchData).find(({ id }) => id === contract_id);

    return {
      data: dayjs(received_at).format('DD/MM/YYYY HH:mm:ss'),
      produto: fuel ? fuel.name : 'Não identificado',
      posto: station ? station.name : 'Não identificado',
      vehicle: vehicle ? vehicle.licensePlate : 'Não identificado',
      litros: amount,
      contrato: contract ? contract.code : '',
      valor_fleetx: Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      valor_nota: Number(total_invoice_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      valor_acordo: Number(contract_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      diferenca: Math.abs(total_invoice_value - Number(contract_value)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
  });

  return conciliationInfo;
}

const ConciliationReport = () => {
  return (
    <Permission permission={PERMISSIONS.REPORT_CONCILIATION}>
      <ReportList
        basePath="fillins"
        title="Conciliação"
        resource="reports/conciliation"
        filters={<ListFilters />}
        sort={{ field: 'received_at', order: 'DESC' }}
        perPage={25}
        filterDefaultValues={{
          from: dayjs().subtract(1, 'month').startOf('day').toISOString(),
          to: dayjs().endOf('day').toISOString(),
          isDiffFillin: false,
        }}
        pagination={<ListPagination />}
        bulkActionButtons={false}
        fileName='relatorio_de_conciliação'
        customExporter={conciliationExporter}
        hasSummary={false}
        titleOnPrint="Relatório de Conciliação"
        filterTablePrint={<FilterTablePrint />}
        landscape={true}
      >
        <ConciliationList />
      </ReportList>
    </Permission>
  );
}

export default ConciliationReport;
