export const MAX_IDS_PER_REQUEST = 150;

export const PERMISSIONS = {
  ADMIN: 'admin',

  // Cadastro
  LIST_DRIVERS: 'list-drivers',
  LIST_PROFILES: 'list-profiles',
  LIST_USERS: 'list-users',
  LIST_COST_CENTERS: 'list-cost-centers',
  LIST_AGGREGATED_DRIVES: 'list-aggregated-drivers',
  LIST_ATTENDANTS: 'list-station-attendants',
  LIST_CUSTOMERS: 'list-customers',
  LIST_MANAGERS: 'list-managers',
  LIST_NOTIFICATIONS: 'list-push-notifications',
  LIST_ROUTES: 'list-routes',
  LIST_CHATS: 'list-chats',
  LIST_VALETS: 'list-valets',
  LIST_VEHICLES: 'list-vehicles',
  LIST_VEHICLE_BASES: 'list-vehicle-bases',
  LIST_OPERATIONS: 'list-operations',
  LIST_VEHICLE_GROUPS: 'list-vehicle-groups',
  LIST_VOUCHERS: 'list-vouchers',
  EDIT_CONTRACTS: 'edit-contracts',
  CREATE_CONTRACTS: 'create-contracts',
  APPROVE_CONTRACTS: 'approve-contracts',
  MAILING_LIST: 'mailing-list',
  EDIT_STATIONS: 'edit-stations',
  EDIT_SUB_COMPANIES: 'edit-sub-companies',
  CREATE_INVOICE: 'create-invoice',
  CREATE_PROFILE: 'create-profile',
  UPDATE_PROFILE: 'update-profile',
  DELETE_PROFILE: 'delete-profile',
  DELETE_INVOICE: 'delete-invoice',

  // Listagem
  LIST_FILLINS: 'list-fillins',
  LIST_STATIONS: 'list-stations',
  LIST_CONTRACTS: 'list-contracts',
  LIST_CHAINS: 'list-chains',
  LIST_RATNGS: 'list-ratings',
  LIST_COMPANIES: 'list-companies',
  LIST_FILLIN_ATTEMPTS: 'list-fillin-attempts',
  LIST_SUB_COMPANIES: 'list-sub-companies',
  EDIT_BILLS: 'edit-bills',
  LIST_INVOICE: 'list-invoice',
  APPROVE_FILLINS: 'approve-fillins',

  // Contingência
  CREATE_FILLINS: 'create-fillins',
  LIST_OTP_CODES: 'opt-codes',
  FILL_IN_VEHICLE: 'fill-in-vehicle',

  // Configurações
  COMPANY_CONFIG: 'company-config',

  // Relatórios
  REPORT_FILLINS: 'report-fillins',
  REPORT_FINANCIAL: 'report-financial',
  REPORT_BLOCKED_DRIVERS: 'report-blocked-drivers',
  REPORT_TRANSACTIONS: 'report-transactions',
  REPORT_AVERAGE: 'report-average',
  REPORT_AVERAGE_BY_DRIVER: 'report-average-by-driver',
  REPORT_AVERAGE_BY_VEHICLE: 'report-average-by-vehicle',
  REPORT_INVOICES: 'report-invoices',
  REPORT_BILLS: 'report-bills',
  REPORT_PRICES: 'report-prices',
  REPORT_PRICE_CHANGES: 'report-price-changes',
  REPORT_VOUCHERS: 'report-vouchers',
  REPORT_DETAILED_PRICE: 'report-detailed-price',
  REPORT_BEST_PRICES: 'report-best-prices',
  REPORT_CONCILIATION: 'report-conciliation',

};