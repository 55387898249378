import UploadFileIcon from '@mui/icons-material/UploadFile';
import { CircularProgress, Tooltip } from '@mui/material';
import { Button, FileInput, TextField } from 'ra-ui-materialui';
import React, { useRef, useState } from 'react';
import { UseInputValue, Validator, useInput } from 'react-admin';

interface UploadFileButtonProps {
  source: string;
  validate?: Validator[];
  invoiceProductIndexes: any[];
  resetInitialValues: () => void;
  setInvoiceFillins: (value: any) => void;
  onChange: (files: Array<Blob | { rawFile: Blob }>, invoicesInput: UseInputValue, invoiceFiles: UseInputValue, destCNPJInput: UseInputValue, totalInvoiceValueInput: UseInputValue, vehicleBaseNameInput: UseInputValue) => Promise<void>;
}

const UploadFileButton: React.FC<UploadFileButtonProps> = (props) => {

  const {
    source,
    onChange,
    validate,
    invoiceProductIndexes
  } = props;
  const fileRef = useRef<any>(null);

  const invoicesInput = useInput({ source: 'invoices' });
  const destCNPJInput = useInput({ source: 'destCNPJ' });
  const totalInvoiceValueInput = useInput({ source: 'totalInvoiceValue' });
  const vehicleBaseNameInput = useInput({ source: 'vehicleBaseName' });
  const invoiceFiles = useInput({ source: 'invoiceFiles' });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const lastInvoice = (invoicesInput.field.value || []).slice(-1)[0];
  const invoiceProductIndex = lastInvoice && (invoiceProductIndexes.find(i => i.invoiceNumber === lastInvoice.invoiceNumber)?.index || 0);
  const disabledButton = Boolean(lastInvoice && lastInvoice.invoiceProducts && lastInvoice.invoiceProducts.length !== invoiceProductIndex);

  const handleChange = (files: Array<Blob | { rawFile: Blob }>) => {
    if (!Array.isArray(files)) { return null; }

    setIsLoading(true);

    onChange(files, invoicesInput, invoiceFiles, destCNPJInput, totalInvoiceValueInput, vehicleBaseNameInput)
      .finally(() => setIsLoading(false));
  }

  return (
    <>
      <Tooltip
        title={disabledButton ? 'Nota fiscal sem abastecimento vinculado, por favor, selecione os abastecimentos listados abaixo.' : null}
        placement="right"
        arrow
      >
        <span>
          <Button
            label="Importar XML"
            size="large"
            variant="outlined"
            startIcon={isLoading ? <CircularProgress size={18} /> : <UploadFileIcon />}
            onClick={() => {
              if (fileRef.current) {
                fileRef.current.click();
              }
            }}
            disabled={disabledButton || isLoading}
          />
        </span>
      </Tooltip>

      <FileInput
        source={source}
        inputProps={{ ref: fileRef }}
        sx={{ display: 'none' }}
        label="Arquivos XML"
        maxSize={1000000}
        onChange={handleChange}
        validate={validate}
        accept="text/xml"
        multiple
      >
        <TextField source="title" />
      </FileInput>
    </>
  );
}

export default UploadFileButton;